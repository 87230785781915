import NextI18Next from 'next-i18next/dist/commonjs';

const NextI18NextInstance = new NextI18Next({
  defaultLanguage: 'us',
  otherLanguages: ['uk', 'ch', 'be', 'de', 'cy'],
  localeSubpaths: {
    us: 'us',
    uk: 'uk',
    ch: 'ch',
    be: 'be',
    de: 'de',
    cy: 'cy',
  },
  browserLanguageDetection: true,
  serverLanguageDetection: true,
});

export default NextI18NextInstance;

/* Optionally, export class methods as named exports */
export const {
  appWithTranslation,
  withTranslation,
  useTranslation,
  Router,
  Link,
  i18n,
  Trans,
} = NextI18NextInstance;
